/*
   Color Palette

   These colors shouldn't be used in a component directly.
   Always assign them a functional variable name before use.

   Tool for color name lookup: http://chir.ag/projects/name-that-color
**/


/*
   Forwood Style Guide Color Palette

   Comments indicate legacy color variables to replace with Forwood Palette colors
**/
$forwood-blue-1: #2ED2E9;
$forwood-blue-2: #17A7FE;
$forwood-blue-3: #0173B4;
$forwood-blue-4: #002A74;

$forwood-gray-1: #DCDCDC;
$forwood-gray-2: #B3B3B3;
$forwood-gray-3: #656565;
$forwood-gray-4: #343434;

$white: #ffffff;
$black: #000000;
$dodger-blue: #1890FF;
$dodger-blue-light: #40A9FF;
$pomegranate: #F52222;
$mercury: #E8E8E8;
$alto: #d9d9d9;
$athens-grey: #f0f2f5;

/*
   Functional variables

   Components should refer to their functional name rather than the color name.
   These should always refer to a palette color from above
**/

$primary: $forwood-blue-3;
$highlight: $forwood-blue-2;
$secondary: $forwood-gray-1;
$error: $pomegranate;

// CompanyStructure
$company-structure-matched-node-color: $forwood-gray-3;
$company-structure-unmatched-node-color: $forwood-gray-2;

// User list
$user-list-table-bg: $white;
$user-list-table-row-bg: $white;
$user-list-col-header-bg: $forwood-blue-3;
$user-list-col-header-color: $white;
$user-list-col-header-row-color: rgba(94, 93, 82, 1);
$user-list-col-data-title-color: rgba(94, 93, 82, 0.55);
$user-list-col-border-color: $mercury;
$user-list-row-even-bg: rgba(0, 0, 0, 0.03);
$user-list-row-disabled-bg: rgba($forwood-gray-1, .5);
$user-list-sort-col-bg: $forwood-blue-4;
$user-list-sort-control-color: rgba(255, 255, 255, 0.3);
$user-list-sort-border-color: $white;
$user-list-sort-control-span-color: rgba(255, 255, 255, 0.1);
$user-list-roles-icon-color: $primary;
$user-list-roles-icon-color-mobile: $white;
$user-list-name-link-color: $primary;
$user-list-filter-input-border-color: $alto;
$user-list-row-disabled-color: white;

// General
$disabled-item-color: rgba($black, .3);
