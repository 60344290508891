@import '../../sass/colors';

:local(.filterOptions) {
  margin: 15px 0 0 0;
  display: grid;
  grid-gap: 10px;
}

:local(.container) {
  display: flex;
  justify-content: space-between;

  @media (max-width: 769px) {
    display: block;
  }
}

:local(.showFilter) {
  width: auto;

  @media (max-width: 769px) {
    width: 100%;
  }
}
:local(.applyFilterContainer) {
  :local(.resetFilterButton) {
    float: left;
  }
  :local(.applyFilterButton) {
    float: right;
  }
  @media (max-width: 769px) {
    text-align: center !important;
    :local(.resetFilterButton) {
      float: none;
    }
    :local(.applyFilterButton) {
      float: none;
    }
  }
}
