@import '../../sass/colors';

:local(.tag) {
  font-size: 8px;
  line-height: 1;
  padding: 2px;
}

:local(.controlTag) {
  font-size: 10px;
  line-height: 1;
  padding: 2px;
}
